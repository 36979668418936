import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';

import UtahGymnasticsImage from '../../assets/images/publication-banners/Utah-Gymnastics.jpg';

const UtahGymnastics = (props) => (
  <Layout>
    <Helmet>
      <title>Unversity of Utah Gymnastics - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="Advertising and event information for the Utah Gymnastics season programs. Please contact Mills Publishing for availability."
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Unversity of Utah Gymnastics</h1>
          </header>
          <span className="image main">
            <img src={UtahGymnasticsImage} alt="" />
          </span>
          <p className="box">
            As one of the most successful collegiate programs in NCAA history,
            the U of U gymnastics team makes for an excellent advertising
            opportunity. With tens of thousands of fans attending each season,
            your ad will be seen by a burgeoning group of loyal fans.
          </p>
          <ul className="actions">
            <li>
              <a
                href="https://utahutes.com/sports/womens-gymnastics"
                className="button special"
              >
                <i className="icon fa-globe"> </i>University of Utah Gymnastics
                Website
              </a>
            </li>
          </ul>
          <h2>
            University of Utah Gymnastics {new Date().getFullYear()} Season
            Information
          </h2>
          <em>
            IMPORTANT: Due do the current times please remember these dates may
            not be accurate until we learn more.
          </em>
          <div className="grid-wrapper">
            <div className="col-6 box">
              <h3>TBD</h3>
              <p>
                <strong>TBD</strong> <br />
                TBD
                <br />
                <br />
                <h5>Advertising Information</h5>
                <section className="box">
                  File Ready Deadline: TBD <br />
                  Circulation: TBD
                </section>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default UtahGymnastics;
